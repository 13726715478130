import styled from "styled-components";

import { MicroCmsImage } from "@/components/MicroCmsImage";

export const BigIconImg = styled(MicroCmsImage).withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  width: 67px;
  height: 75px;

  ${(props) =>
    props.isMobile &&
    `
    width: 48px;
    height: 54px;
    `}
`;

export const SmallIconImg = styled(MicroCmsImage)`
  height: 20px;
  flex-shrink: 0;
`;

export const AssistantBannerImg = styled(MicroCmsImage)`
  width: 100%;
  height: 100%;
`;
