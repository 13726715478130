import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { useRef, useCallback, useState, useMemo } from "react";

import { LipSync } from "@/features/lipSync";
import { recoBackendClient } from "@/libs/apiClient";
import {
  characterMotionAtom,
  characterSrcAtom,
  clientConfigAtom,
} from "@/utils/atoms";

type ReturnHookType = {
  playAudio: (text: string) => Promise<void>;
  cancelSpeech: () => void;
  isSpeaking: boolean;
  isCharacterSpeaking: boolean;
};

export const useCharacterSpeech = (): ReturnHookType => {
  const isSpeaking = useRef(false);
  const [isCharacterSpeaking, setIsCharacterSpeaking] = useState(false);
  const client = useAtomValue(clientConfigAtom);
  const characterMotion = useAtomValue(characterMotionAtom);
  const characterSrc = useAtomValue(characterSrcAtom);
  const setCharacterSrc = useSetAtom(characterSrcAtom);

  const lipSync = useMemo(() => new LipSync(new AudioContext()), []);

  const fetchOriginalAudio = useCallback(
    async (text: string) => {
      if (!client.voiceApiUrl) return;
      const response = await axios.post(
        client.voiceApiUrl,
        { text },
        {
          responseType: "blob", // レスポンスをblobとして受け取る
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // eslint-disable-next-line consistent-return
      return response.data as Blob;
    },
    [client.voiceApiUrl],
  );

  const fetchCharacterAudio = useCallback(
    async (text: string) => {
      const response = await recoBackendClient.post(
        "/voices/clova",
        {
          text,
          voice_name: client.voiceCharacter,
          volume: client.voiceCharacterVolume,
          speed: client.voiceCharacterSpeed,
          alpha: client.voiceCharacterAlpha,
          pitch: client.voiceCharacterPitch,
        },
        {
          responseType: "blob", // レスポンスをblobとして受け取る
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // eslint-disable-next-line consistent-return
      return response.data as Blob;
    },
    [
      client.voiceCharacter,
      client.voiceCharacterAlpha,
      client.voiceCharacterPitch,
      client.voiceCharacterSpeed,
      client.voiceCharacterVolume,
    ],
  );

  const fetchAudioData = useCallback(
    async (text: string): Promise<Blob | undefined> => {
      try {
        if (client.voiceApiUrl) {
          return await fetchOriginalAudio(text);
        }
        if (client.voiceCharacter) {
          return await fetchCharacterAudio(text);
        }
      } catch (error) {
        console.error("Error fetching audio:", error);
      }
      return undefined;
    },
    [
      client.voiceApiUrl,
      client.voiceCharacter,
      fetchCharacterAudio,
      fetchOriginalAudio,
    ],
  );

  const playAudio = useCallback(
    (text: string) =>
      new Promise<void>((resolve) => {
        if (!isSpeaking.current) {
          fetchAudioData(text).then((audioData) => {
            if (audioData) {
              setIsCharacterSpeaking(true);
              if (client.isSignage) {
                const imageSources: {
                  [key in typeof characterMotion]: string | undefined;
                } = {
                  attention: client.character.attention,
                  happy: client.character.happy,
                  talk: client.character.talk,
                  respectful: client.character.respectful,
                  greeting: client.character.greeting,
                  default: client.character.normal,
                };

                const currentSrc = characterSrc;
                const availableKeys = Object.keys(imageSources).filter(
                  (key) =>
                    imageSources[key as keyof typeof imageSources] !==
                    currentSrc,
                ) as (keyof typeof imageSources)[];
                const randomKey =
                  availableKeys[
                    Math.floor(Math.random() * availableKeys.length)
                  ];
                const src = imageSources[randomKey] || "";
                setCharacterSrc(src);
              }

              audioData.arrayBuffer().then((arrayBuffer) => {
                lipSync.playFromArrayBuffer(arrayBuffer, () => {
                  setIsCharacterSpeaking(false);
                  resolve();
                });
              });
            }
          });
        }
      }),
    [
      client.isSignage,
      client.character,
      fetchAudioData,
      lipSync,
      characterSrc,
      setCharacterSrc,
    ],
  );

  const cancelSpeech = useCallback(() => {
    lipSync.stop();
    isSpeaking.current = false;
    setIsCharacterSpeaking(false);
  }, [lipSync]);

  return {
    playAudio,
    cancelSpeech,
    isSpeaking: isSpeaking.current,
    isCharacterSpeaking,
  };
};
