import React, { useCallback, useEffect, useState } from "react";
import { BsArrowRightCircle } from "react-icons/bs";

import {
  SalesTalkBubbleBox,
  SalesTalkBubbleBoxInner,
  SalesTalkMessageContainer,
  SalesTalkMessageWrap,
  SalesTalkCaption,
  SalesTalkActionLabel,
  SalesTalkArrowButton,
  PoweredByAivyContainer,
  SalesTalkLinkButton,
  SalesTalkActionText,
} from "@/commands/RecoSalesCommand/components/SalesTalkMessage/index.style";
import { SalesTalkMessageSchema } from "@/commands/RecoSalesCommand/schema";
import { useFrameControl } from "@/hooks/useFrameControl";

declare global {
  interface Window {
    gtag?: (type: string, eventName: string, eventParams: unknown) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga?: any;
  }
}

type Props = {
  salesMessages: SalesTalkMessageSchema[];
  isMobile: boolean;
};

export function SalesTalkMessage({ salesMessages, isMobile }: Props) {
  // ランダムに表示するメッセージ
  const [showSalesTalk, setShowSalesTalk] =
    useState<SalesTalkMessageSchema | null>(null);

  // 表示するメッセージを切り替える
  useEffect(() => {
    const setTalk = () => {
      // salesTalkをランダムに選択する
      const salesTalk =
        salesMessages[Math.floor(Math.random() * salesMessages.length)];
      setShowSalesTalk(salesTalk);
    };
    setTalk();
    const timerId = setInterval(setTalk, 5000);

    return () => clearTimeout(timerId);
  }, [salesMessages]);

  const setParantState = useFrameControl();

  // メッセージにカーソルが乗っているかどうか
  const [isHovered, setIsHovered] = useState<boolean>(false);

  // メッセージのリンクをクリックしたときの処理
  const clickLink = useCallback(
    (link: string) => {
      // GAが設定されている場合はイベントを送信する
      if (typeof window.gtag === "function") {
        window.gtag("event", "reco_sales_click", {
          event_category: "sales",
          event_label: link,
          sales_talk: showSalesTalk?.message,
        });
      }
      if (typeof window.ga === "function") {
        window.ga("send", {
          hitType: "event",
          eventCategory: "myCustomEvent",
          eventAction: "myEventAction1",
          eventLabel: "myEventLabel1",
        });
      }
      setParantState({ control: { location: link } });
    },
    [setParantState, showSalesTalk?.message],
  );

  return (
    <SalesTalkBubbleBox
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isMobile={isMobile}
    >
      <SalesTalkBubbleBoxInner isMobile={isMobile}>
        <SalesTalkLinkButton
          isHovered={isHovered}
          isMobile={isMobile}
          onClick={() => clickLink(showSalesTalk?.targetLink ?? "#")}
        >
          <SalesTalkMessageContainer isMobile={isMobile}>
            <SalesTalkMessageWrap isHovered={isHovered} isMobile={isMobile}>
              {showSalesTalk?.message}
            </SalesTalkMessageWrap>
            {showSalesTalk?.caption && (
              <SalesTalkCaption isHovered={isHovered} isMobile={isMobile}>
                {showSalesTalk?.caption}
              </SalesTalkCaption>
            )}
          </SalesTalkMessageContainer>
          <SalesTalkActionLabel isMobile={isMobile}>
            <SalesTalkActionText>
              {showSalesTalk?.targetLabel?.split("\\n").map((line, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  {line}
                  {index !==
                    (showSalesTalk?.targetLabel?.split("\\n").length ?? 0) -
                      1 && <br />}
                </React.Fragment>
              ))}
            </SalesTalkActionText>
            <SalesTalkArrowButton isHovered={isHovered}>
              <BsArrowRightCircle />
            </SalesTalkArrowButton>
          </SalesTalkActionLabel>
        </SalesTalkLinkButton>
        <PoweredByAivyContainer
          isMobile={isMobile}
          onClick={() => window.open("https://aivy.tokyo", "_blank")}
        >
          Powered by AIVy
        </PoweredByAivyContainer>
      </SalesTalkBubbleBoxInner>
    </SalesTalkBubbleBox>
  );
}
