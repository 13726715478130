import { useEffect, useRef, useState } from "react";

type ReturnType = {
  inputAreaHeight: string;
  chatAreaHeight: string;
  inputAreaRef: React.RefObject<HTMLDivElement>;
};

export const useAreaHeight = (): ReturnType => {
  const [inputAreaHeight, setInputAreaHeight] = useState<string>("");
  const [chatAreaHeight, setChatAreaHeight] = useState("456");
  const inputAreaRef = useRef<HTMLDivElement>(null);

  // inputAreaの高さからchatAreaの高さを計算する
  // 高さが変わるときに再計算する
  // ChatAreaWrapperの高さは536pxで固定
  useEffect(() => {
    const currentInputAreaRef = inputAreaRef.current;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setInputAreaHeight(entry.target.clientHeight.toString());
        const newHeight = (536 - entry.target.clientHeight).toString();
        setChatAreaHeight(newHeight);
      });
    });

    if (currentInputAreaRef) {
      observer.observe(currentInputAreaRef);
    }

    return () => {
      if (currentInputAreaRef) {
        observer.unobserve(currentInputAreaRef);
      }
    };
  }, []);

  return {
    inputAreaHeight,
    chatAreaHeight,
    inputAreaRef,
  };
};
