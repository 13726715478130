import { useAtomValue, useSetAtom } from "jotai";

import { SalesButtons } from "@/commands/Circle/components/SalesButtons";
import {
  CircleCommandContainer,
  CircleCharaButton,
  CircleShadow,
} from "@/commands/Circle/index.style";
import { RecoCharacter } from "@/components/RecoCharacter";
import { clientConfigAtom, currentCommandAtom } from "@/utils/atoms";

type Props = {
  isMobile: boolean;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
};

export function Circle({ isMobile, imageLoaded, handleImageLoaded }: Props) {
  const clientConfig = useAtomValue(clientConfigAtom);

  const setCurrentCommand = useSetAtom(currentCommandAtom);

  return (
    <CircleCommandContainer isMobile={isMobile}>
      <CircleCharaButton onClick={() => setCurrentCommand("sales")}>
        <RecoCharacter
          className={`${CircleShadow}`}
          imageLoaded={imageLoaded}
          handleImageLoad={handleImageLoaded}
          isMobile={isMobile}
        />
      </CircleCharaButton>
      <SalesButtons isMobile={isMobile} client={clientConfig} />
    </CircleCommandContainer>
  );
}
