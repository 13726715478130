import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { ChatHistoryType } from "@/commands/Chat/schema";
import { CommentType, DisplayCommentType } from "@/commands/Chat/types";
import {
  chatHistoryAtom,
  chatIdAtom,
  lastUpdatedChatCommentsAtom,
} from "@/utils/atoms";

type ReturnType = {
  chatComments: CommentType[];
  appendChatComment: (message: ChatHistoryType) => void;
  chatId: string;
  chatHistory: ChatHistoryType[];
  setChatHistory: (message: ChatHistoryType[]) => void;
  displayComments: DisplayCommentType[];
};

type Props = {
  isSearching: boolean;
  isError: boolean;
  isMicRecording: boolean;
  clientId: string;
};

const filterChatHistory = (chatHistory: ChatHistoryType[]) =>
  chatHistory.filter(
    (c) =>
      (c.role === "user" || c.role === "assistant") &&
      !c.content.includes("ChatCompletionMessage"),
  );

export const useChatHistory = ({
  isSearching,
  isError,
  isMicRecording,
  clientId,
}: Props): ReturnType => {
  // 会話ログ
  const [chatHistory, setChatHistory] = useAtom(chatHistoryAtom);
  const [lastUpdatedChatComments, setLastUpdatedChatComments] = useAtom(
    lastUpdatedChatCommentsAtom,
  );
  const [chatId, setChatId] = useAtom(chatIdAtom);

  const appendChatComment = useCallback(
    (message: ChatHistoryType) => {
      setChatHistory((prevChatComments) => ({
        ...prevChatComments,
        [clientId]: [
          ...(prevChatComments[clientId] !== undefined
            ? prevChatComments[clientId]
            : []),
          message,
        ],
      }));
      setLastUpdatedChatComments((prevData) => ({
        ...prevData,
        [clientId]: new Date(),
      }));
    },
    [clientId, setChatHistory, setLastUpdatedChatComments],
  );

  useEffect(() => {
    const lastUpdatedChatCommentByClient = lastUpdatedChatComments[clientId];
    if (!lastUpdatedChatCommentByClient) return;
    const now = new Date();
    const lastUpdate = new Date(lastUpdatedChatCommentByClient);
    const diffInHours =
      (now.getTime() - lastUpdate.getTime()) / (1000 * 60 * 60);
    if (diffInHours > 24) {
      setChatHistory((prevData) => ({ ...prevData, [clientId]: [] }));
      setChatId((prevData) => ({ ...prevData, [clientId]: uuidv4() }));
    }
  }, [clientId, lastUpdatedChatComments, setChatHistory, setChatId]);

  useEffect(() => {
    if (chatId[clientId] !== undefined) return;
    setChatId((prevData) => ({ ...prevData, [clientId]: uuidv4() }));
  }, [chatId, clientId, setChatId]);

  useEffect(() => {
    const filteredChatHistory = filterChatHistory(chatHistory[clientId] ?? []);
    if (filteredChatHistory.length > 10) {
      const matchingIndex = chatHistory[clientId].findIndex(
        (chat) =>
          chat.content ===
          filteredChatHistory.find((c) => c.role === "assistant")?.content,
      );
      setChatHistory((prevChatHistory) => ({
        ...prevChatHistory,
        [clientId]: prevChatHistory[clientId].slice(matchingIndex + 1),
      }));
    }
  }, [chatHistory, clientId, setChatHistory]);

  const chatComments = useMemo((): DisplayCommentType[] => {
    let chat = filterChatHistory(chatHistory[clientId] ?? []).map((c) => ({
      content: c.content,
      isCharacter: c.role === "assistant",
      commentId: uuidv4(),
    }));

    if (isSearching) {
      chat = [
        ...chat,
        { isCharacter: true, content: "お調べしています", commentId: uuidv4() },
      ];
    }

    if (isError) {
      chat = [
        ...chat,
        {
          isCharacter: true,
          content: "エラーが発生しました。更新して再度質問してください",
          commentId: uuidv4(),
        },
      ];
    }

    return chat;
  }, [chatHistory, clientId, isError, isSearching]);

  const displayComments = useMemo((): DisplayCommentType[] => {
    if (isMicRecording) {
      return [
        ...chatComments,
        { isCharacter: false, content: "", commentId: uuidv4() },
      ];
    }
    return chatComments;
  }, [chatComments, isMicRecording]);

  return {
    chatHistory: chatHistory[clientId] ?? [],
    chatComments,
    appendChatComment,
    chatId: chatId[clientId],
    setChatHistory: (messages) => {
      setChatHistory((prevData) => ({ ...prevData, [clientId]: messages }));
    },
    displayComments,
  };
};
