import {
  ChatStartGreeting,
  ChatStartGreetingTitle,
  ChatGreetingDetail,
  ChatGreetingIcon,
} from "@/commands/Chat/components/ChatNoComments/index.style";
import { ClientSchema } from "@/types/client";

type Props = {
  isMobile: boolean;
  inputAreaHeight: string;
  client: ClientSchema;
};

export function ChatNoComment({ isMobile, inputAreaHeight, client }: Props) {
  return (
    <ChatStartGreeting isMobile={isMobile} inputAreaHeight={inputAreaHeight}>
      <ChatStartGreetingTitle>なんでも聞いてね！</ChatStartGreetingTitle>
      <ChatGreetingDetail>
        商品やサイトについて
        <br />
        分からないことをご質問ください。
      </ChatGreetingDetail>

      <ChatGreetingIcon src={client.character.talk} />
    </ChatStartGreeting>
  );
}
