import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

export const SalesBaseContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CharacterMessageWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  display: inline-block;
  position: absolute !important;
  width: 90px;
  text-align: center;
  white-space: nowrap;
  top: 19px;
  left: 15px;

  ${(props: { isMobile: boolean }) =>
    props.isMobile &&
    `
      width:80px;
      top: 14px;
      left: 6px
  `}
`;

export const CharacterMessageImage = styled.img`
  width: 100%;
`;

export const SalesCharaContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${(props: { isMobile: boolean }) =>
    props.isMobile &&
    `
    align-items: flex-start;
  `}
`;

export const SalesCharaButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["isOpen", "isMobile"].includes(prop),
})<{
  isOpen: boolean;
  isMobile: boolean;
}>`
  z-index: 10;
  position: absolute;
  bottom: ${(props) => (props.isOpen ? "0px" : "-5px")};
  right: 0px;
  width: 200px;
  height: 200px;
  border: none;
  outline: none;
  transition: bottom 0.6s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  @media (hover: none) {
    &:active {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  ${(props: { isOpen: boolean; isMobile: boolean }) =>
    props.isMobile &&
    `
    bottom: ${props.isOpen ? "10px" : "-20px"};
    right: 3px;
    width:90px;
    height: 90px;
    background: radial-gradient(50% 50% at 50% 50%, #ffffff 65.23%, var(--circle-background) 100%);
    border-radius: 50%;
  `}

  & > div:nth-child(2) {
    position: relative;
    right: -6px;
    bottom: -12px;

    ${(props: { isMobile: boolean }) =>
      props.isMobile &&
      `
      width: 78px;
      height: 78px;
      right: 0px;
      bottom: -10px;
    `}
  }
`;

export const SalesButtonCloseButton = styled(AiOutlineClose).withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  z-index: 100;
  position: absolute;
  top: 15px;
  right: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  border-radius: 50%;
  padding: 4px;
  background: var(--button-background);
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  ${(props) =>
    props.isMobile &&
    `
    bottom: 70px;
    right: 0px;
  `}
`;
