import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";

import { MicroCmsImage } from "@/components/MicroCmsImage";
import {
  characterMotionAtom,
  characterSrcAtom,
  clientConfigAtom,
} from "@/utils/atoms";

type Props = {
  className?: string;
  imageLoaded: boolean;
  handleImageLoad: () => void;
  isMobile?: boolean;
  selectedWidth?: string;
  selectedMobileWidth?: string;
  mode?: "default" | "chat" | "close" | "circle" | "assistant";
};

export function RecoCharacter({
  className,
  imageLoaded,
  handleImageLoad,
  isMobile,
  selectedWidth,
  selectedMobileWidth,
  mode,
}: Props): JSX.Element {
  const client = useAtomValue(clientConfigAtom);
  const characterMotion = useAtomValue(characterMotionAtom);
  const setCharacterSrc = useSetAtom(characterSrcAtom);
  const characterWidth = useMemo(() => {
    if (mode === "default") return "auto";
    if (isMobile) return selectedMobileWidth || "12rem";
    return selectedWidth || "12rem";
  }, [mode, isMobile, selectedMobileWidth, selectedWidth]);

  const imageComponent = useMemo(() => {
    const imageSources: {
      [key in typeof characterMotion]: string | undefined;
    } = {
      attention: client.character.attention,
      happy: client.character.happy,
      talk: client.character.talk,
      respectful: client.character.respectful,
      greeting: client.character.greeting,
      default: client.character.normal,
    };
    const src =
      (characterMotion && characterMotion !== "normal"
        ? imageSources[characterMotion]
        : imageSources.default) || "";

    setCharacterSrc(src);

    return (
      <MicroCmsImage
        className={className}
        src={src}
        format="webp"
        w={mode !== "default" ? parseInt(characterWidth, 10) * 24 : undefined}
        imageLoaded={imageLoaded}
        handleImageLoad={handleImageLoad}
      />
    );
  }, [
    className,
    client,
    characterMotion,
    characterWidth,
    imageLoaded,
    handleImageLoad,
    setCharacterSrc,
    mode,
  ]);

  return imageComponent;
}
