import styled, { css, keyframes } from "styled-components";

export const ChatCommentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isCharacter", "isMobile"].includes(prop),
})<{
  isCharacter: boolean;
  isMobile: boolean;
}>`
  width: -webkit-fill-available;
  margin-top: 8px;
  display: flex;
  align-items: flex-end;

  .commentMargin {
    margin-left: 10px;
  }

  ${({ isCharacter }) =>
    isCharacter
      ? ""
      : `
      margin-left: 77px;
  justify-content: flex-end;
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? `
      margin-left: 58px;
    `
      : ""}
  `};
`;

export const ChatIconArea = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile", "isLastComment"].includes(prop),
})<{
  isMobile: boolean;
  isLastComment: boolean;
}>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${({ isLastComment, isMobile }) =>
    isLastComment
      ? `
        width:${isMobile ? "48px" : "67px"};
        height:${isMobile ? "54px" : "75px"};
      `
      : `
        width: 32px;
        height: 32px;
        border: 1px solid #c4dce5;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: ${isMobile ? "2px" : "20px"};
        padding: 3px 3px 0px 3px;
      `}
`;

const CharacterComment = css`
  margin-right: 24px;
  border-radius: 25px 25px 25px 0px;
  background: #d1e4f1;
`;

const UserComment = css`
  border-radius: 25px 25px 0px 25px;
  background: var(--button-background);
`;

export const CommentWrapper = styled.div<{
  $isCharacter: boolean;
  $isLastComment?: boolean;
  $isMobile: boolean;
}>`
  max-width: ${({ $isMobile }) => ($isMobile ? "277px" : "284px")};
  padding: 16px;

  ${({ $isCharacter }) => ($isCharacter ? CharacterComment : UserComment)}

  ${({ $isLastComment }) =>
    $isLastComment
      ? css`
          margin-bottom: 24px;
        `
      : ""}
`;

const UserContent = css`
  color: #fff;
  font-weight: 500;
`;

const CharacterContent = css`
  color: #16566f;
  font-weight: 300;
`;

const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  25% {
    content: "・";
  }
  50% {
    content: "・・";
  }
  75% {
    content: "・・・";
  }
  100% {
    content: "";
  }
`;

const animatedStyle = css`
  min-width: 160px;
  display: inline-block;
  &:after {
    content: "・・・";
    animation: ${ellipsisAnimation} 1s infinite;
  }
`;

const defaultStyle = css``;

export const ChatContent = styled.span<{
  $isCharacter: boolean;
  $isMicRecording: boolean;
  $content: string;
  $isLastComment?: boolean;
  $isSearching?: boolean;
}>`
  ${({ $isCharacter }) => ($isCharacter ? CharacterContent : UserContent)};
  ${({ $isMicRecording, $content, $isLastComment, $isSearching }) =>
    // ellipsisAnimationをかけるのは$isLastCommentがtrueの時のみ
    // isLastCommentがtrueの時、さらに以下の条件を満たす場合にanimationをかける
    // マイク録音中かつ、会話内容が空の場合
    // 検索中の場合
    $isLastComment && (($isMicRecording && $content === "") || $isSearching)
      ? animatedStyle
      : defaultStyle}
`;
