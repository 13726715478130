import {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  forwardRef,
} from "react";
import TextareaAutosize from "react-textarea-autosize";

export type Props = {
  id?: string;
  disabled?: boolean;
  className?: string;
  name?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string | number;
  onKeyDown?: (e: ReactKeyboardEvent<HTMLTextAreaElement>) => void;
  onCompositionStart?: () => void;
  onCompositionEnd?: () => void;
};

export const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const placeholder = props.disabled
    ? "お話しください。"
    : "質問を入力してください。";

  return (
    <TextareaAutosize
      id={props.id}
      ref={ref}
      className={`${props.className}`}
      name={props.name}
      maxLength={props.maxLength}
      onChange={props.onChange}
      value={props.value}
      placeholder={placeholder}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      onCompositionStart={props.onCompositionStart}
      onCompositionEnd={props.onCompositionEnd}
      minRows={1}
      maxRows={10}
    />
  );
});
