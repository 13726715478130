import { useAtomValue } from "jotai";
import { JSX, useMemo } from "react";

import smallIconPath from "@/assets/images/icon/icon.png";
import { BigIconImg, SmallIconImg } from "@/components/Icon/index.style";
import { characterMotionAtom, clientConfigAtom } from "@/utils/atoms";

type IconProps = {
  isLastComment?: boolean;
  isMobile: boolean;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
};

export function Icon({
  isLastComment,
  isMobile,
  imageLoaded,
  handleImageLoaded,
}: IconProps): JSX.Element {
  const characterMotion = useAtomValue(characterMotionAtom);
  const clientConfig = useAtomValue(clientConfigAtom);

  const bigIconSrc = useMemo(() => {
    switch (characterMotion) {
      case "respectful":
        return clientConfig.character.respectful;
      default:
        return clientConfig.character.normal;
    }
  }, [
    characterMotion,
    clientConfig.character.normal,
    clientConfig.character.respectful,
  ]);

  const smallIconSrc = useMemo(
    () => clientConfig.smallIcon || smallIconPath,
    [clientConfig],
  );

  return isLastComment ? (
    <BigIconImg
      src={bigIconSrc}
      isMobile={isMobile}
      imageLoaded={imageLoaded}
      handleImageLoad={handleImageLoaded}
      w={67}
    />
  ) : (
    <SmallIconImg
      src={smallIconSrc}
      imageLoaded={imageLoaded}
      handleImageLoad={handleImageLoaded}
      w={20}
    />
  );
}
