import {
  ChatAtalkerInfo,
  ChatAtalkerInfoResponse,
  ChatAtalkerRequest,
  ChatAtalkerResponse,
  EvaluationChatRequest,
} from "@/commands/Chat/schema";
import {
  CmsListResponseType,
  aTalkerClient,
  cmsClient,
} from "@/libs/apiClient";

const chatAtalkerResponseToSchema = (
  res: ChatAtalkerInfoResponse,
): ChatAtalkerInfo => ({
  ...res,
});

export const getChatAtalkerInfoByClientId = async ({
  clientId,
}: {
  clientId: string;
}): Promise<ChatAtalkerInfo> => {
  const res = await cmsClient.get<CmsListResponseType<ChatAtalkerInfoResponse>>(
    {
      endpoint: "chat-atalker-info",
      queries: {
        filters: `client[equals]${clientId}`,
      },
    },
  );
  return chatAtalkerResponseToSchema(res.contents[0]);
};

export const postAtalkerChat = async ({
  user_id,
  data_base_id,
  user_text,
  chat_history,
  chat_id,
}: ChatAtalkerRequest): Promise<ChatAtalkerResponse> => {
  const res = await aTalkerClient.post<ChatAtalkerResponse>(
    `cognitive-search/${user_id}`,
    {
      user_id,
      data_base_id,
      user_text,
      chat_history,
      chat_id,
    },
  );
  return res.data;
};

export const postEvaluation = async (
  evaluationData: EvaluationChatRequest,
): Promise<void> => {
  await aTalkerClient.post<void>(
    "cognitive-search/evaluation-chat",
    evaluationData,
  );
};
