import styled, { keyframes } from "styled-components";

export const SalesTalkfadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const SalesTalkBubbleBox = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  position: absolute;
  bottom: 30px;
  right: 220px;
  width: 285px;
  height: auto;
  background: transparent;
  animation: ${SalesTalkfadeIn} 0.6s ease;
  cursor: pointer;

  ${(props) =>
    props.isMobile &&
    `
    bottom: 0;
    right: 0;
    width: 100vw;
    height: auto;
    border: none;
    background: transparent;
    padding: 0;

    &:after {
      display: none;
    }
  `}
`;

export const SalesTalkBubbleBoxInner = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background: #f7fafc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 0;
  color: #16566f;
  transition: background-color 0.3s ease;

  &:after {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(247, 250, 252, 0);
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left-width: 31px;
    border-right-width: 31px;
    margin-top: -10px;
    border-left-color: #f7fafc;
    left: 100%;
    top: 50%;
    transition: border-left-color 0.3s ease;
  }

  &:hover {
    background-color: #fffce4;

    &:after {
      border-left-color: #fffce4;
    }
  }

  ${(props) =>
    props.isMobile &&
    `
    border-radius: 0;
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
    border: none;

    &:after {
      display: none;
    }
  `}
`;

export const SalesTalkMessageContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 8px 12px;
  text-align: left;
  ${(props) =>
    props.isMobile &&
    `
    padding: 8px 16px;
  `}
`;

// TODO: resetCSSに負けてしまうため!importantが必要だが、なんとかしたい
export const SalesTalkMessageWrap = styled.p.withConfig({
  shouldForwardProp: (prop) => !["isHovered", "isMobile"].includes(prop),
})<{ isHovered?: boolean; isMobile: boolean }>`
  margin-bottom: 0px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
  letter-spacing: 0em !important;
  text-align: left !important;

  ${(props) =>
    props.isMobile &&
    `
    font-size: 14px !important;
    margin-bottom: 0 !important;
    line-height: 18px !important;
  `}
`;

// TODO: resetCSSに負けてしまうため!importantが必要だが、なんとかしたい
export const SalesTalkCaption = styled.p.withConfig({
  shouldForwardProp: (prop) => !["isHovered", "isMobile"].includes(prop),
})<{ isHovered?: boolean; isMobile: boolean }>`
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  letter-spacing: 0em !important;
  text-align: left !important;

  ${(props) =>
    props.isMobile &&
    `
    display: none !important;
  `}
`;

export const SalesTalkActionLabel = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{ isMobile: boolean }>`
  display: flex !important;
  align-items: center;
  justify-content: start;
  margin: 0;
  padding: 8px 12px;
  width: 100%;
  background: var(--button-background);
  color: #fff;
  ${(props) =>
    props.isMobile &&
    `
    padding-left: 1rem;
  `}
`;
export const SalesTalkActionText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
`;

export const SalesTalkArrowButton = styled("span").withConfig({
  shouldForwardProp: (prop) => !["isHovered"].includes(prop),
})<{ isHovered?: boolean }>`
  padding-top: 3px;
  background: transparent;
  border: none;
  transition: transform 0.3s ease;
  transform: ${() => (props) =>
    props.isHovered ? "translateX(0.8rem)" : "translateX(0.5rem)"};
`;

export const SalesTalkLinkButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["isHovered", "isMobile"].includes(prop),
})<{ isHovered?: boolean; isMobile: boolean }>`
  background: transparent; // デフォルトの背景をリセット
  border: none; // デフォルトの境界線をリセット
  padding: 0; // デフォルトのパディングをリセット
  cursor: pointer; // ホバー時にカーソルをポインタに変更
  z-index: 1;
`;

export const PoweredByAivyContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isHovered", "isMobile"].includes(prop),
})<{ isHovered?: boolean; isMobile: boolean }>`
  padding-left: 1rem;
  justify-content: center; /* ここで中央揃えを指定 */
  width: 100%;
  font-size: 9px !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  background-color: #3d3a3a;
  font-weight: bold;
  line-height: 1.4;
  z-index: 1;

  ${(props) =>
    props.isMobile &&
    `
    justify-content: flex-start; /* isMobileがtrueの場合、左端から表示 */
    padding-left: 1rem;
  `}
`;
