type LipSyncAnalyzeResult = {
  volume: number;
};

const TIME_DOMAIN_DATA_LENGTH = 2048;

// キャラクターの口が動くわけではなさそうなので、lipSyncが適切か怪しい
export class LipSync {
  public readonly audio: AudioContext;

  public readonly analyser: AnalyserNode;

  public readonly timeDomainData: Float32Array;

  private bufferSource?: AudioBufferSourceNode;

  public constructor(audio: AudioContext) {
    this.audio = audio;

    this.analyser = audio.createAnalyser();
    this.timeDomainData = new Float32Array(TIME_DOMAIN_DATA_LENGTH);
  }

  public update(): LipSyncAnalyzeResult {
    this.analyser.getFloatTimeDomainData(this.timeDomainData);

    let volume = 0.0;
    for (let i = 0; i < TIME_DOMAIN_DATA_LENGTH; i += 1) {
      volume = Math.max(volume, Math.abs(this.timeDomainData[i]));
    }

    // cook
    volume = 1 / (1 + Math.exp(-45 * volume + 5));
    if (volume < 0.1) volume = 0;

    return {
      volume,
    };
  }

  public async playFromArrayBuffer(buffer: ArrayBuffer, onEnded?: () => void) {
    const audioBuffer = await this.audio.decodeAudioData(buffer);

    this.bufferSource = this.audio.createBufferSource();
    this.bufferSource.buffer = audioBuffer;

    this.bufferSource.connect(this.audio.destination);
    this.bufferSource.connect(this.analyser);
    this.bufferSource.start();
    if (onEnded != null) {
      this.bufferSource.addEventListener("ended", onEnded);
    }
  }

  public stop() {
    if (this.bufferSource == null) return;
    this.bufferSource.stop();
  }
}
