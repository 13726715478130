import styled, { css } from "styled-components";

export const CircleCommandContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{ isMobile: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
  background: transparent;

  ${(props: { isMobile: boolean }) =>
    props.isMobile &&
    `
    bottom: -20px;
    right: -30px;
  `}
`;

export const CircleCharaButton = styled.button`
  z-index: 100;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
`;

export const CircleShadow = css`
  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;
