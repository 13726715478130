import { captureException } from "@sentry/react";
import { useEffect, useState, useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { postMiibo } from "@/commands/Assistant/api";
import { ChatMessage, MiiboResponse } from "@/commands/Assistant/schema";
import { useCharacterSpeech } from "@/hooks/useCharacterSpeech";

const uid = uuid();

type ChatOptions = {
  label: string;
  value: string;
};

type Props = {
  code: string;
};

export type MiiboHookTypes = {
  talkMiibo: (text: string) => void;
  options: string[];
  userState: {
    [key: string]: string;
  };
  chatLog: ChatMessage[];
  isLoading: boolean;
  lastAssistantMessage: string;
  chatOptions: ChatOptions[];
  sendChat: (text: string) => void;
  startMiibo: () => void;
  reset: () => void;
  isSpeaking: boolean;
};

export const useMiibo = ({ code }: Props): MiiboHookTypes => {
  const [chatLog, setChatLog] = useState<ChatMessage[]>([]);

  const [userState, setUserState] = useState<{
    [key: string]: string;
  }>({});

  const [options, setOptions] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { playAudio, cancelSpeech, isCharacterSpeaking } = useCharacterSpeech();

  const requestMiibo = useCallback(
    async (text: string): Promise<MiiboResponse | undefined> => {
      if (code === "") return undefined;
      try {
        const response = await postMiibo({
          code,
          uid,
          utterance: text,
        });
        return response;
      } catch (error) {
        captureException(error);
        return undefined;
      }
    },
    [code],
  );

  const talkMiibo = useCallback(
    async (text: string) => {
      setIsLoading(true);
      if (text.length > 0) {
        setChatLog((prev) => [
          ...prev,
          {
            content: text,
            role: "user",
          },
        ]);
      }
      const res = await requestMiibo(text);
      if (res) {
        setUserState(res.userState);
        setOptions(res.bestResponse.options);
        setChatLog((prev) => [
          ...prev,
          {
            content: res.bestResponse.utterance,
            role: "assistant",
          },
        ]);
      }
      setIsLoading(false);
    },
    [requestMiibo, setChatLog],
  );

  const startMiibo = useCallback(() => {
    if (code) {
      talkMiibo("");
    }
  }, [code, talkMiibo]);

  const lastAssistantMessage = useMemo((): string => {
    const lastMessage =
      chatLog
        .slice()
        .reverse()
        .find((log) => log.role === "assistant")?.content || "";
    return lastMessage;
  }, [chatLog]);

  const sendChat = useCallback(
    (message: string): void => {
      if (message.length > 0) {
        cancelSpeech();
        talkMiibo(message);
      }
    },
    [cancelSpeech, talkMiibo],
  );

  const chatOptions = useMemo(
    (): { label: string; value: string }[] =>
      options.map((option) => ({
        label: option,
        value: option,
      })),
    [options],
  );

  useEffect(() => {
    if (lastAssistantMessage !== "") {
      (async () => {
        await playAudio(lastAssistantMessage);
      })();
    }
  }, [lastAssistantMessage, playAudio]);

  const reset = useCallback(() => {
    cancelSpeech();
    setChatLog([]);
    setUserState({});
    setOptions([]);
  }, [cancelSpeech]);

  return {
    talkMiibo,
    userState,
    options,
    chatLog,
    isLoading,
    lastAssistantMessage,
    sendChat,
    chatOptions,
    startMiibo,
    reset,
    isSpeaking: isCharacterSpeaking,
  };
};
