import ReactMarkdown, { Components } from "react-markdown";

import {
  ChatCommentWrapper,
  ChatContent,
  ChatIconArea,
  CommentWrapper,
} from "@/commands/Chat/components/ChatCommentArea/index.style";
import { CommentType } from "@/commands/Chat/types";
import { Icon } from "@/components/Icon";

type Props = {
  isMobile: boolean;
  comment: CommentType;
  isMicRecording: boolean;
  isLastComment?: boolean;
  isSearching: boolean;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
};

const LinkRenderer: Components["a"] = function LinkRenderer({
  href,
  children,
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const OlRenderer: Components["ol"] = function OlRenderer({ children }) {
  return <ol style={{ paddingLeft: "10px" }}>{children}</ol>;
};

export function ChatCommentArea({
  isMobile,
  comment,
  isMicRecording,
  isLastComment,
  isSearching,
  imageLoaded,
  handleImageLoaded,
}: Props) {
  return (
    <ChatCommentWrapper isCharacter={comment.isCharacter} isMobile={isMobile}>
      {comment.isCharacter && (
        <ChatIconArea isMobile={isMobile} isLastComment={!!isLastComment}>
          <Icon
            isLastComment={isLastComment}
            isMobile={isMobile}
            imageLoaded={imageLoaded}
            handleImageLoaded={handleImageLoaded}
          />
        </ChatIconArea>
      )}
      <CommentWrapper
        className={comment.isCharacter ? "commentMargin" : ""}
        $isCharacter={comment.isCharacter}
        $isLastComment={isLastComment}
        $isMobile={isMobile}
      >
        <ChatContent
          $isCharacter={comment.isCharacter}
          $isMicRecording={isMicRecording}
          $content={comment.content}
          $isLastComment={isLastComment}
          $isSearching={isSearching}
        >
          <ReactMarkdown components={{ a: LinkRenderer, ol: OlRenderer }}>
            {comment.content}
          </ReactMarkdown>
        </ChatContent>
      </CommentWrapper>
    </ChatCommentWrapper>
  );
}
