import { useState, useEffect } from "react";

// 親サイトに実行可能な操作
export interface FrameControl {
  location?: string;
}

// 補完が効くように定義していますが必要なければ [key: string]: string|number でもcssのプロパティ名と同じであれば親側は問題ありません
export interface FrameStyle {
  top?: string | number;
  bottom?: string | number;
  left?: string | number;
  right?: string | number;
  width?: string | number;
  height?: string | number;
  backgroundImage?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
}

export interface FrameState {
  control?: FrameControl;
  style?: FrameStyle;
  backGroundImg?: string; // SVG画像のURLまたはデータURIを格納
}

// CSSパラメタが数値の場合は単位にpxを付与する
const addUnitToStyle = (styles?: FrameStyle): FrameStyle | undefined => {
  if (styles === undefined) {
    return undefined;
  }

  const styleWithUnit = {} as FrameStyle;
  Object.keys(styles).forEach((k) => {
    const elm = k as keyof FrameStyle;
    if (
      elm !== "backgroundImage" &&
      elm !== "backgroundSize" &&
      elm !== "backgroundPosition"
    ) {
      if (typeof styles[elm] === "number") {
        styleWithUnit[elm] = `${styles[elm]}px`;
      } else {
        styleWithUnit[elm] = styles[elm];
      }
    } else {
      styleWithUnit[elm] =
        elm === "backgroundImage" ? `url(${styles[elm]})` : styles[elm];
    }
  });
  return styleWithUnit;
};
// 親サイトに送信する
export const useFrameControl = (initialValue?: FrameState) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const styleWithUnit = addUnitToStyle(state?.style);

    window.parent.postMessage(
      {
        source: "reco-web-agent",
        control: state?.control,
        style: styleWithUnit,
      },
      "*",
    );
  }, [state, setState]);

  return setState;
};
