import { useEffect, useRef } from "react";

import { CommentType } from "@/commands/Chat/types";

type ReturnType = {
  chatAreaContainerRef: React.RefObject<HTMLDivElement>;
};

type Props = {
  comments: CommentType[];
};

export const useScrollToBottom = ({ comments }: Props): ReturnType => {
  const chatAreaContainerRef = useRef<HTMLDivElement>(null);

  // コメントが追加されたら一番下までスクロールする
  const scrollToBottom = () => {
    if (chatAreaContainerRef.current) {
      chatAreaContainerRef.current.scrollTop =
        chatAreaContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  return {
    chatAreaContainerRef,
  };
};
