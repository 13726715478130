import { AiOutlineClose } from "react-icons/ai";
import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const AssistantWrapper = styled.div<{ $isSignage: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: ${(props) =>
    props.$isSignage ? "white" : "rgba(255, 255, 255, 0.6)"};
  animation: ${(props) =>
    props.$isSignage
      ? "none"
      : css`
          ${fadeIn} 0.5s ease-in-out
        `};
`;

const poyoyon3 = keyframes`
  0%, 20% {
    transform: skew(0deg, 0deg);
  }
  2.55% {
    transform: skew(5deg, 5deg);
  }
  5% {
    transform: skew(-4deg, -4deg);
  }
  7.5% {
    transform: skew(3deg, 3deg);
  }
  10% {
    transform: skew(-2deg, -2deg);
  }
  12.5% {
    transform: skew(1deg, 1deg);
  }
  150% {
    transform: skew(-0.6deg, -0.6deg);
  }
  17.5% {
    transform: skew(0.3deg, 0.3deg);
  }
`;

export const AssistantBannerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  position: absolute;

  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? css`
          top: 0.5rem;
          left: 0.5rem;
          width: 80%;

          animation: ${poyoyon3} 5s infinite;
          opacity: 1;
        `
      : css`
          bottom: 0.5rem;
          left: 50%;
          width: 400px;
          transform: translateX(-50%);
        `}
`;

export const BackgroundWrapper = styled.div<{ src: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center;
`;

export const LogoWrapper = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  border: none;
  padding: 0;
  z-index: 999;
`;

export const AssistantIconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["isMobile", "hasAssistantBanner"].includes(prop),
})<{ isMobile: boolean; hasAssistantBanner: boolean }>`
  width: 100%;
  height: 100%;

  ${({
    isMobile,
    hasAssistantBanner,
  }: {
    isMobile: boolean;
    hasAssistantBanner: boolean;
  }) =>
    hasAssistantBanner && !isMobile
      ? css`
          position: absolute;
          bottom: 3.5rem;
        `
      : ""}
`;

export const ChatAreaWrapper = styled.div`
  padding: 5rem 1.25rem;
  background-color: hsla(0, 0%, 100%, 0.5);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
`;

export const AssistantCloseButton = styled(AiOutlineClose).withConfig({
  shouldForwardProp: (prop) => !["isChatLogExpanded"].includes(prop),
})<{ isChatLogExpanded: boolean }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: pointer;
  z-index: 9999;
  display: ${(props) => (props.isChatLogExpanded ? "none" : "block")};
`;

export const CloseButton = styled(AiOutlineClose)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: pointer;
`;

export const ChatLogWrapper = styled.div<{ role: string }>`
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.role === "user" ? "flex-end" : "flex-start"};
`;

export const ChatLogContents = styled.div<{ role: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.role === "user" ? "flex-end" : "flex-start"};
  width: fit-content;
`;

export const ChatLogContent = styled.div<{ role: string }>`
  padding: 1rem;
  background: ${(props) =>
    props.role === "user" ? "var(--button-background)" : "#fff"};

  color: ${(props) => (props.role === "user" ? "#fff" : "#000")};
  border-radius: ${(props) =>
    props.role === "user" ? "25px 25px 0px 25px" : "25px 25px 25px 0"};
`;

export const AssistantCommentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["isMobile", "hasAssistantBanner", "hasBannerLink"].includes(prop),
})<{ isMobile: boolean; hasAssistantBanner: boolean; hasBannerLink: boolean }>`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => {
    if (!props.isMobile && props.hasAssistantBanner && props.hasBannerLink)
      return "7rem";
    return "2rem";
  }};

  max-width: 28rem;
  max-height: calc(100dvh - 100px);
`;

export const AssistantComment = styled.div`
  background: #fff;
  color: #000;
  border-radius: 25px 25px 25px 0;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;
  justify-content: left;
  overflow-y: scroll;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  25% {
    content: "・";
  }
  50% {
    content: "・・";
  }
  75% {
    content: "・・・";
  }
  100% {
    content: "";
  }
`;

export const CommentLoading = styled.p`
  width: 40px;
  display: inline-block;
  &:after {
    content: "・・・";
    animation: ${ellipsisAnimation} 1s infinite;
  }

  justify-content: left;
`;

export const UserSelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const UserSelectButton = styled("button").withConfig({
  shouldForwardProp: (prop) => !["isButtonVisible"].includes(prop),
})<{ isButtonVisible: boolean }>`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 8px 12px;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
  min-width: 70px;
  display: ${(props) => (props.isButtonVisible ? "block" : "none")};
  font-size: 12px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const UserActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 1rem 0;
  width: -webkit-fill-available;

  .textInputWidth {
    flex-grow: 1;
  }

  .buttonSendMargin {
    margin: 0 0 0 12px;
  }

  .buttonMicroPhoneMargin {
    margin: 0 0 0 8px;
  }
`;

export const AssistantStartButton = styled.button`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 16px 24px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  min-width: 140px;
  font-size: 24px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const AssistantEndButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  max-width: 28rem;
  max-height: calc(100dvh - 100px);
`;

export const AssistantEndButton = styled.button`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 16px 24px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  min-width: 140px;
  font-size: 24px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;
