import styled from "styled-components";

export const ChatStartGreeting = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile", "inputAreaHeight"].includes(prop),
})<{
  isMobile: boolean;
  inputAreaHeight: string;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.isMobile &&
    `
    height: calc(100lvh - ${props.inputAreaHeight}px);
  `}
`;

export const ChatStartGreetingTitle = styled.h3`
  font-size: 20px;
`;

export const ChatGreetingDetail = styled.p`
  margin-top: 4px;
  color: #16566f;
  text-align: center;
`;

export const ChatGreetingIcon = styled.img`
  height: 178px;
  margin-top: 24px;
`;
