import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";

import {
  getChatAtalkerInfoByClientId,
  postAtalkerChat,
  postEvaluation,
} from "@/commands/Chat/api";
import {
  ChatAtalkerResponse,
  ChatHistoryType,
  EvaluationType,
} from "@/commands/Chat/schema";
import { ClientSchema } from "@/types/client";

type GetAnswerProps = {
  userText: string;
  chatHistory: ChatHistoryType[];
  chatId: string;
};

type ReturnType = {
  getAnswer: (
    props: GetAnswerProps,
  ) => Promise<ChatAtalkerResponse | undefined>;
  handleOnClickEvaluation: (props: EvaluationType) => Promise<void>;
  currentEvaluation: EvaluationType | "";
};

type Props = {
  chatId: string;
  clientConfig: ClientSchema;
};

export const useAtalker = ({ clientConfig, chatId }: Props): ReturnType => {
  const [requestInfo, setRequestInfo] = useState<{
    userId: string;
    dataBaseId: string;
  }>({
    userId: "",
    dataBaseId: "",
  });

  const [currentEvaluation, setCurrentEvaluation] = useState<
    EvaluationType | ""
  >("");

  const fetchAtalkerInfo = useCallback(async () => {
    try {
      const res = await getChatAtalkerInfoByClientId({
        clientId: clientConfig.id,
      });
      setRequestInfo({
        userId: res.userId,
        dataBaseId: res.dataBaseId,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [clientConfig.id]);

  useEffect(() => {
    if (!clientConfig.id) return;
    fetchAtalkerInfo();
  }, [clientConfig.id, fetchAtalkerInfo]);

  const getAnswer = useCallback(
    async ({
      userText,
      chatHistory,
    }: GetAnswerProps): Promise<ChatAtalkerResponse | undefined> => {
      if (!requestInfo.dataBaseId) return undefined;
      const res = await postAtalkerChat({
        user_id: requestInfo.userId,
        data_base_id: requestInfo.dataBaseId,
        user_text: userText,
        chat_history: chatHistory,
        chat_id: chatId,
      });
      return res;
    },
    [chatId, requestInfo.dataBaseId, requestInfo.userId],
  );

  const handleOnClickEvaluation = useCallback(
    async (evaluation: EvaluationType) => {
      try {
        postEvaluation({
          chat_id: chatId,
          evaluation,
        });
        setCurrentEvaluation(evaluation);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [chatId, setCurrentEvaluation],
  );

  return {
    getAnswer,
    handleOnClickEvaluation,
    currentEvaluation,
  };
};
