import { useSetAtom } from "jotai";

import {
  ClosedBaseContainer,
  ClosedCharaButton,
} from "@/commands/Close/index.style";
import { RecoCharacter } from "@/components/RecoCharacter";
import { currentCommandAtom } from "@/utils/atoms";

type Props = {
  isMobile: boolean;
  imageLoaded: boolean;
  handleImageLoad: () => void;
};

export function Close({
  isMobile,
  imageLoaded,
  handleImageLoad,
}: Props): JSX.Element {
  const setCurrentCommand = useSetAtom(currentCommandAtom);

  return (
    <ClosedBaseContainer>
      {/* キャラクター動作 */}
      <ClosedCharaButton
        type="button"
        isMobile={isMobile}
        onClick={() => {
          setCurrentCommand("sales");
        }}
      >
        <RecoCharacter
          imageLoaded={imageLoaded}
          handleImageLoad={handleImageLoad}
          selectedWidth="6rem"
          selectedMobileWidth="6rem"
        />
      </ClosedCharaButton>
    </ClosedBaseContainer>
  );
}
