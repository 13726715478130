import styled, { keyframes } from "styled-components";

export const ClosedBaseContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const closeAnimation = keyframes`
  0% {
    bottom: 15px;
    display: none;
  }
  100% {
    bottom: -45px;
    display: block;
  }
`;

const spCloseAnimation = keyframes`

  0% {
    bottom: 15px;
    display: none;
  }
  100% {
    bottom: -35px;
    display: block;
  }
`;

export const ClosedCharaButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  position: absolute;
  background: none;
  border-radius: 50%;
  right: 15px;
  width: 100px;
  height: 100px;
  border: none;
  outline: none;
  transition: bottom 0.6s ease;
  cursor: pointer;
  animation: ${(props) => (props.isMobile ? spCloseAnimation : closeAnimation)}
    0.5s ease-in-out forwards;

  ${(props: { isMobile: boolean }) =>
    props.isMobile &&
    `
    bottom: -20px;
    right: 3px;
    width: 78px;
    height: 78px;
  `}

  & > div {
    position: relative;
    right: 6px;

    ${(props: { isMobile: boolean }) =>
      props.isMobile &&
      `
    width: 78px;
    height: 78px;
  `}
  }
`;
