import * as Sentry from "@sentry/react";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getSalesTalkMessageByDisplayUrl } from "@/commands/RecoSalesCommand/api";
import { SalesTalkMessageSchema } from "@/commands/RecoSalesCommand/schema";
import { parentUrlAtom } from "@/utils/atoms";

type ReturnType = SalesTalkMessageSchema[];

type Props = {
  clientId: string;
};

const wildcardMatch = (url: string, pattern: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const escapedPattern = pattern.replace(/([.+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  const regexPattern = escapedPattern.replace(/\*/g, ".*");
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(url);
};

export const useSalesTalk = ({ clientId }: Props): ReturnType => {
  const [fetchSalesMessage, setFetchSalesMessage] = useState<
    SalesTalkMessageSchema[]
  >([]);

  const parentUrl = useAtomValue(parentUrlAtom);

  const salesMessages = useMemo(() => {
    if (!parentUrl) return [];
    const url = new URL(parentUrl);
    return fetchSalesMessage.filter((s) =>
      wildcardMatch(
        `${url.origin}${url.pathname}/`.replace(/\/+$/, "/"),
        s.displayUrl,
      ),
    );
  }, [parentUrl, fetchSalesMessage]);

  const fetchSalesTalkAndFront = useCallback(async () => {
    try {
      if (parentUrl === undefined) return;
      const url = new URL(parentUrl);
      const salesTalk = await getSalesTalkMessageByDisplayUrl({
        url,
      });
      setFetchSalesMessage(salesTalk ?? []);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [parentUrl, setFetchSalesMessage]);

  useEffect(() => {
    if (!clientId) return;
    fetchSalesTalkAndFront();
  }, [clientId, fetchSalesTalkAndFront]);

  return salesMessages;
};
