import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent as ReactKeyboardEvent,
} from "react";
import styled from "styled-components";

export type Props = {
  id?: string;
  disabled?: boolean;
  className?: string;
  name?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  onKeyDown?: (e: ReactKeyboardEvent<HTMLInputElement>) => void;
  onCompositionStart?: () => void;
  onCompositionEnd?: () => void;
  isMobile?: boolean;
};

const TextInput = styled.input.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  color: #16566f;
  padding: 16px 24px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #c4dce5;
  background: #fff;
  transition: border 0.3s ease;
  cursor: pointer;

  ${(props) =>
    props.isMobile &&
    `
    padding: 12px 16px;
    `}

  &::placeholder {
    color: #6e9db0;
  }

  &:hover {
    border: 1px solid #21a8d2;
  }

  &:focus {
    outline: none;
    border: 1px solid #21a8d2;
  }
`;

export function Input({
  id,
  disabled,
  className,
  name,
  maxLength,
  onChange,
  value,
  type,
  onKeyDown,
  onCompositionStart,
  onCompositionEnd,
  isMobile = false,
}: Props): JSX.Element {
  const placeholder = disabled
    ? "お話しください。"
    : "質問を入力してください。";
  return (
    <TextInput
      id={id}
      className={`${className}`}
      name={name}
      maxLength={maxLength}
      onChange={onChange}
      value={value}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onCompositionStart={onCompositionStart}
      onCompositionEnd={onCompositionEnd}
      isMobile={isMobile}
    />
  );
}
