import { forwardRef } from "react";
import { IoIosSend } from "react-icons/io";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  border-radius: 50%;
  border: none;
  position: relative;
  background: var(--button-background);
  flex-shrink: 0;
  transition: background ease 0.3s;
  width: 48px;
  height: 48px;
  padding: 9px 11.7px 12px 7.3px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: #e1eef3;
  }
`;

const StyledIoIosSend = styled(IoIosSend)`
  color: #fff;
  width: 32px;
  height: 32px;
`;

export const ButtonSend = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onButtonDown: () => void;
    isMicRecording: boolean;
  }
>(({ className, isMicRecording, onClick, onMouseDown, onButtonDown }, ref) => {
  const handleButtonDown = () => {
    onButtonDown();
  };

  return (
    <Button
      className={className}
      type="button"
      disabled={isMicRecording}
      ref={ref}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onPointerDown={handleButtonDown}
      onTouchStart={handleButtonDown}
    >
      <StyledIoIosSend />
    </Button>
  );
});
