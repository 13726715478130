import { forwardRef } from "react";
import { BsFillHandThumbsDownFill } from "react-icons/bs";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  border-radius: 50%;
  border: none;
  position: relative;
  background: none;
`;

const StyledBsFillHandThumbsDownFill = styled(
  BsFillHandThumbsDownFill,
).withConfig({
  shouldForwardProp: (prop) => !["isActive"].includes(prop),
})<{
  isActive: boolean;
}>`
  color: ${(props) => (props.isActive ? "#22b4d2" : "rgb(221, 221, 221);")};
`;

export const Bad = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onButtonDown: () => void;
    isActive: boolean;
  }
>(({ className, onClick, onMouseDown, onButtonDown, isActive }, ref) => {
  const handleButtonDown = () => {
    onButtonDown();
  };

  return (
    <Button
      className={className}
      type="button"
      ref={ref}
      aria-label="bad"
      onClick={onClick}
      onMouseDown={onMouseDown}
      onPointerDown={handleButtonDown}
      onTouchStart={handleButtonDown}
    >
      <StyledBsFillHandThumbsDownFill isActive={isActive} size={24} />
    </Button>
  );
});
