import { useAtomValue, useSetAtom } from "jotai";
import { useMemo, useState } from "react";

import {
  AssistantWrapper,
  AssistantBannerWrapper,
  AssistantCloseButton,
  AssistantIconWrapper,
  BackgroundWrapper,
  LogoWrapper,
} from "@/commands/Assistant/index.style";
import { AssistantNormalMode } from "@/commands/Assistant/normalMode";
import { AssistantSignageMode } from "@/commands/Assistant/signageMode";
import { AssistantBannerImg } from "@/components/Icon/index.style";
import { RecoCharacter } from "@/components/RecoCharacter";
import { SignageRecoCharacter } from "@/components/SignageRecoCharacter";
import NamixBackgroundSvg from "@/public/namixBackground.svg";
import NamixLogoWebp from "@/public/namixLogo.webp";
import { clientConfigAtom, currentCommandAtom } from "@/utils/atoms";

type Props = {
  oneWayDirection?: string;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
  isMobile: boolean;
};

export default function Assistant({
  oneWayDirection,
  imageLoaded,
  handleImageLoaded,
  isMobile,
}: Props) {
  const [isChatLogExpanded, setIsChatLogExpanded] = useState<boolean>(false);

  const client = useAtomValue(clientConfigAtom);
  const setCurrentCommand = useSetAtom(currentCommandAtom);

  const assistantBannerSrc = useMemo(
    () => client.assistantBanner || "",
    [client],
  );

  const bannerLink = useMemo(() => client.bannerLink || "", [client]);

  return (
    <AssistantWrapper $isSignage={client.isSignage}>
      {assistantBannerSrc && bannerLink && (
        <AssistantBannerWrapper isMobile={isMobile}>
          <a
            href={bannerLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Assistant Banner"
          >
            <AssistantBannerImg
              src={assistantBannerSrc}
              imageLoaded={imageLoaded}
              handleImageLoad={handleImageLoaded}
            />
          </a>
        </AssistantBannerWrapper>
      )}

      {!client.isSignage && (
        <AssistantCloseButton
          onClick={() => {
            if (oneWayDirection !== "") {
              setCurrentCommand("sales");
            } else {
              setCurrentCommand("circle");
            }
          }}
          isChatLogExpanded={isChatLogExpanded}
        />
      )}

      <AssistantIconWrapper
        isMobile={isMobile}
        hasAssistantBanner={Boolean(assistantBannerSrc)}
      >
        {client.isSignage && (
          <BackgroundWrapper src={NamixBackgroundSvg}>
            <LogoWrapper onClick={() => window.location.reload()}>
              <img src={NamixLogoWebp} alt="" />
            </LogoWrapper>
          </BackgroundWrapper>
        )}
        {client.isSignage ? (
          <SignageRecoCharacter
            handleImageLoad={handleImageLoaded}
            imageLoaded={imageLoaded}
          />
        ) : (
          <RecoCharacter
            handleImageLoad={handleImageLoaded}
            imageLoaded={imageLoaded}
            selectedWidth={isMobile ? "20rem" : "35rem"}
          />
        )}
      </AssistantIconWrapper>
      {!client.isSignage && (
        <AssistantNormalMode
          setIsChatLogExpanded={setIsChatLogExpanded}
          isChatLogExpanded={isChatLogExpanded}
          clientId={client.id}
          isMobile={isMobile}
          hasAssistantBanner={Boolean(assistantBannerSrc)}
          hasBannerLink={Boolean(bannerLink)}
        />
      )}
      {client.isSignage && <AssistantSignageMode clientId={client.id} />}
    </AssistantWrapper>
  );
}
