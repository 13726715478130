import axios from "axios";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createClient } from "microcms-js-sdk";

import {
  ClientResponseSchema,
  ClientSchema,
  clientResponseToSchema,
} from "@/types/client";

const CMS_DOMAIN = import.meta.env.VITE_MICROCMS_DOMAIN;
const API_KEY = import.meta.env.VITE_MICROCMS_API_KEY;

const A_TALKER_API_URL = import.meta.env.VITE_A_TALKER_API_URL;
const RECO_BACKEND_API_URL = import.meta.env.VITE_RECO_BACKEND_API_URL;

export type CmsListResponseType<T> = {
  contents: T[];
  limit: number;
  offset: number;
  totalCount: number;
};

export const cmsClient = createClient({
  serviceDomain: CMS_DOMAIN,
  apiKey: API_KEY,
});

export const getClientByClientCode = async (
  clientCode: string,
): Promise<ClientSchema | null> => {
  try {
    const response = await cmsClient.get<
      CmsListResponseType<ClientResponseSchema>
    >({
      endpoint: "clients",
      queries: {
        filters: `clientCode[equals]${clientCode}`,
      },
    });
    return clientResponseToSchema(response.contents[0]);
  } catch (error) {
    // エラーハンドリングをここに記述
    return null;
  }
};

export const aTalkerClient = axios.create({
  baseURL: A_TALKER_API_URL, // ベースURLを設定
  headers: {},
});

export const recoBackendClient = axios.create({
  baseURL: RECO_BACKEND_API_URL, // ベースURLを設定
  headers: {},
});
