import {
  SalesTalkMessageSchema,
  SalesTalkMessageResponseSchema,
} from "@/commands/RecoSalesCommand/schema";
import { CmsListResponseType, cmsClient } from "@/libs/apiClient";

const salesTalkResponseToSchema = (
  res: SalesTalkMessageResponseSchema,
): SalesTalkMessageSchema => ({
  targetLabel: res.salesTalkMessages.targetLabel,
  message: res.salesTalkMessages.message,
  isLock: res.salesTalkMessages.isLock,
  targetLink: res.salesTalkMessages.targetLink,
  caption: res.salesTalkMessages.caption,
  highlightedMessage: res.salesTalkMessages.highlightedMessage,
  displayUrl: res.displayUrl,
});

export const getSalesTalkMessageByDisplayUrl = async ({
  url,
}: {
  url: URL;
}): Promise<SalesTalkMessageSchema[]> => {
  const res = await cmsClient.get<
    CmsListResponseType<SalesTalkMessageResponseSchema>
  >({
    endpoint: "sales_talks",
    queries: {
      filters: `displayUrl[contains]${url.origin}`,
    },
  });

  return res.contents.map((c) => salesTalkResponseToSchema(c));
};
