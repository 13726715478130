import { AiOutlineClose } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const AssistantWrapper = styled.div<{ $isSignage: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) =>
    props.$isSignage ? "white" : "rgba(255, 255, 255, 0.6)"};
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const AssistantIconWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  & > div {
    // アイコンの位置を下に修正したい場合
    // position: absolute;
    // bottom: 0;

    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const AssistantCloseButton = styled(AiOutlineClose).withConfig({
  shouldForwardProp: (prop) => !["isChatLogExpanded"].includes(prop),
})<{ isChatLogExpanded: boolean }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: pointer;
  display: ${(props) => (props.isChatLogExpanded ? "none" : "block")};
`;

export const AssistantCommentWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AssistantComment = styled.div`
  position: relative;
  color: #0d0d0d;
  padding: 135px 82px;
  margin: 2rem;
  width: 1582px;
  max-height: 1140px;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.84);
  border: 8px solid rgba(0, 0, 0, 1);

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  > * {
    font-size: 68px !important;
    font-weight: 700;
  }
`;
const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  25% {
    content: "・";
  }
  50% {
    content: "・・";
  }
  75% {
    content: "・・・";
  }
  100% {
    content: "";
  }
`;

export const CommentLoading = styled.p`
  width: 200px;
  height: 120px;
  font-size: 68px !important;
  display: inline-block;
  color: #000;
  &:after {
    content: "・・・";
    animation: ${ellipsisAnimation} 1s infinite;
  }

  justify-content: left;
`;

export const UserSelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 100px;
`;

export const UserSelectButton = styled("button").withConfig({
  shouldForwardProp: (prop) => !["isButtonVisible"].includes(prop),
})<{ isButtonVisible: boolean }>`
  background: #1877f2;
  border: 8px solid rgba(13, 13, 13, 1);
  color: #fff;
  border-radius: 100px;
  padding: 24px 36px;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  min-width: 70px;
  font-weight: 700;

  display: ${(props) => (props.isButtonVisible ? "block" : "none")};
  font-size: 68px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const UserActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 1rem;
  width: -webkit-fill-available;
  flex-direction: column;
  align-items: center;
`;

export const EndSpeakingWording = styled.p`
  font-size: 80px !important;
  font-weight: 700 !important;
  color: #fff;
  height: 100px;
`;

export const AssistantStartButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--button-background);
  border: 8px solid rgba(13, 13, 13, 1);
  color: #fff;
  border-radius: 100px;
  cursor: pointer;
  font-size: 68px !important;
  font-weight: 700;

  width: 600px;
  height: 200px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const AssistantEndButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

export const AssistantEndButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--button-background);
  border: 8px solid rgba(13, 13, 13, 1);
  color: #fff;
  border-radius: 100px;
  cursor: pointer;
  font-size: 68px !important;
  font-weight: 700;

  width: 600px;
  height: 200px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;
