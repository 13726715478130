import { useState } from "react";
import styled from "styled-components";

import { Bad } from "@/commands/Chat/components/EvaluationArea/Bad";
import { Good } from "@/commands/Chat/components/EvaluationArea/Good";
import { EvaluationType } from "@/commands/Chat/schema";

const EvaluationAreaWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isMobile &&
    `
    width: 100%;
    padding: 12px 24px;
  `}
`;

type Props = {
  currentEvaluation: EvaluationType | "";
  handleOnGood: () => void;
  handleOnBad: () => void;
  isMobile: boolean;
};

export function ChatEvaluationArea({
  currentEvaluation,
  handleOnGood,
  handleOnBad,
  isMobile,
}: Props) {
  const [evaluation, setEvaluation] = useState<EvaluationType | "">(
    currentEvaluation,
  );

  const handleGood = () => {
    handleOnGood();
    setEvaluation("good");
  };

  const handleBad = () => {
    handleOnBad();
    setEvaluation("bad");
  };

  return (
    <EvaluationAreaWrapper isMobile={isMobile}>
      <div>この回答はお役に立ちましたでしょうか</div>
      <Good onButtonDown={handleGood} isActive={evaluation === "good"} />
      <Bad onButtonDown={handleBad} isActive={evaluation === "bad"} />
    </EvaluationAreaWrapper>
  );
}
