import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";

import { SalesTalkMessage } from "@/commands/RecoSalesCommand/components/SalesTalkMessage";
import { useSalesTalk } from "@/commands/RecoSalesCommand/hooks/useSalesTalk";
import {
  SalesBaseContainer,
  SalesButtonCloseButton,
  SalesCharaButton,
  SalesCharaContainer,
} from "@/commands/RecoSalesCommand/index.style";
import { RecoCharacter } from "@/components/RecoCharacter";
import {
  CharacterMotions,
  characterMotionAtom,
  clientConfigAtom,
  currentCommandAtom,
} from "@/utils/atoms";

type Props = {
  oneWayDirection?: string;
  isMobile: boolean;
  imageLoaded: boolean;
  handleImageLoad: () => void;
};

export function RecoSalesCommand({
  oneWayDirection,
  isMobile,
  imageLoaded,
  handleImageLoad,
}: Props): JSX.Element {
  const clientConfig = useAtomValue(clientConfigAtom);
  const setCurrentCommand = useSetAtom(currentCommandAtom);
  const setCharacterMotion = useSetAtom(characterMotionAtom);
  // 表示するかどうか
  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  const salesTalkMessage = useSalesTalk({ clientId: clientConfig.id });
  return (
    <SalesBaseContainer>
      {isDisplay && salesTalkMessage.length > 0 && (
        <>
          {/* セールスメッセージを表示する */}
          <SalesTalkMessage
            salesMessages={salesTalkMessage}
            isMobile={isMobile}
          />
        </>
      )}
      {/* キャラクター動作 */}
      <SalesCharaContainer isMobile={isMobile}>
        <SalesCharaButton
          isOpen={isDisplay}
          type="button"
          isMobile={isMobile}
          onClick={() => {
            if (oneWayDirection === "assistant") {
              setCurrentCommand("assistant");
            } else if (oneWayDirection === "chat") {
              setCurrentCommand("chat");
            } else {
              setCurrentCommand("circle");
            }
            const motion =
              CharacterMotions[
                Math.floor(Math.random() * CharacterMotions.length)
              ];
            setCharacterMotion(motion);

            setIsDisplay((prev) => !prev);
          }}
        >
          <RecoCharacter
            imageLoaded={imageLoaded}
            handleImageLoad={handleImageLoad}
            isMobile={isMobile}
            selectedMobileWidth="6rem"
            mode="default"
          />
        </SalesCharaButton>

        <SalesButtonCloseButton
          isMobile={isMobile}
          onClick={() => setCurrentCommand("close")}
        >
          <IoMdClose />
        </SalesButtonCloseButton>
      </SalesCharaContainer>
    </SalesBaseContainer>
  );
}
